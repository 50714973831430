<template>
  <div>
    <div class="vx-row ml-1 mt-3">
      <div class="vx-row mb-6" style="width: 70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Customer Number</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <multiselect
            v-model="customers.selected"
            :options="customers.options"
            placeholder="Type to search"
            track-by="text"
            label="text"
            :max-height="125"
            :internal-search="false"
            @search-change="handleSearchChange"
            @clear="handleSearchClear"
          >
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center"></div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
        </div>
      </div>
    </div>
    <div class="vx-row mb-12">
      <!-- <div :class="[detail ? 'md:w-1/2' : '', 'md:w-1/1']" class="core vx-col w-full mb-base"> -->
      <div
        :class="[
          detail ? 'core vx-col w-full mb-base md:w-1/2' : '',
          'core vx-col w-full mb-base md:w-1/1',
        ]"
      >
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th sort-key="from_currency">Action</vs-th>
            <vs-th sort-key="to_currency">Customer Data</vs-th>
            <vs-th sort-key="exchange_rate">SO Data</vs-th>
            <vs-th sort-key="date_echange_rate">DO Data</vs-th>
            <vs-th sort-key="date_echange_rate">POD Data</vs-th>
            <vs-th sort-key="date_echange_rate">POD Status</vs-th>
            <vs-th sort-key="date_echange_rate">POD Confirmed</vs-th>
            <vs-th sort-key="date_echange_rate">Invoice</vs-th>
          </template>

          <template slot-scope="{ data }">
            <!-- <template> -->
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">
                <div class="flex flex-row space-x-1">
                  <vx-tooltip
                    text="Print Invoice"
                    v-if="
                      ['D000', 'COD'].includes(data[indextr].PaymentTermName)
                    "
                  >
                    <vs-button
                      :disabled="
                        data[indextr].DeliveryOrder.InvoiceLine.Invoice.Code ==
                        ''
                      "
                      type="line"
                      icon-pack="feather"
                      @click="handlePrintInvoice(data[indextr].DeliveryOrder)"
                      icon="icon-printer"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Print Proforma Invoice" v-else>
                    <vs-button
                      :disabled="
                        data[indextr].DeliveryOrder.ProofOfDelivery.Status != 4
                      "
                      type="line"
                      icon-pack="feather"
                      @click="
                        handlePrintProformaInvoice(
                          data[indextr].DeliveryOrder.id
                        )
                      "
                      icon="icon-printer"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Detail">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      @click="handleDetail(data[indextr].DeliveryOrder.id)"
                      icon="icon-eye"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Confirm to Complete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      :disabled="
                        data[indextr].DeliveryOrder.ProofOfDelivery.Status != 4
                      "
                      @click="
                        handleConfirm(
                          data[indextr].DeliveryOrder.id,
                          data[indextr].DeliveryOrder.ProofOfDelivery.Status
                        )
                      "
                      icon="icon-shopping-cart"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>

              <vs-td :data="data[indextr].customer_data"
                >{{ data[indextr].CustomerCode }}
                {{ data[indextr].CustomerName }}</vs-td
              >
              <vs-td :data="data[indextr].so_data">{{
                data[indextr].Code
              }}</vs-td>
              <vs-td :data="data[indextr].do_data">{{
                data[indextr].DeliveryOrder.Code
              }}</vs-td>
              <vs-td :data="data[indextr].do_data">
                Code :
                <b>{{ data[indextr].DeliveryOrder.ProofOfDelivery.Code }}</b
                ><br />
                GI :
                <b>{{
                  data[indextr].DeliveryOrder.ProofOfDelivery.GiNumber
                }}</b>
              </vs-td>
              <vs-td :data="data[indextr].do_data">{{
                formatStatusDelivery(
                  data[indextr].DeliveryOrder.ProofOfDelivery.StatusDelivery
                )
              }}</vs-td>
              <vs-td :data="data[indextr].do_data">{{
                data[indextr].DeliveryOrder.ProofOfDelivery.Status == 4
                  ? "YES"
                  : "NO"
              }}</vs-td>
              <vs-td
                v-if="['D000', 'COD'].includes(data[indextr].PaymentTermName)"
                :data="data[indextr].do_data"
                >{{
                  data[indextr].DeliveryOrder.ProofOfDelivery.Status == 4
                    ? data[indextr].DeliveryOrder.InvoiceLine.Invoice.Code
                    : ""
                }}</vs-td
              >

              <vs-td v-else :data="data[indextr].do_data">{{
                data[indextr].DeliveryOrder.ProofOfDelivery.Status == 4
                  ? data[indextr].DeliveryOrder.InvoiceProforma.Code
                  : ""
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <!-- <div v-if="detail" :class="[detail ? 'md:w-1/2' : '', 'md:w-1/1']" class="core vx-col w-full mb-base"> -->
        <!-- <div class="vertical-divider" v-if="detail" :class="[detail ? 'core vx-col w-full mb-base md:w-1/2' : '', 'core vx-col w-full mb-base md:w-1/1']"> -->
        <div
          class="vertical-divider core vx-col w-full mb-base"
          v-if="detailS"
          :class="[detail ? 'md:w-1/2' : 'close md:w-1/1']"
        >
          <!-- <vs-row class="vertical-divider"> -->
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto min-h-0"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col class="flex flex-row align-middle min-h-0">
            <div>
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </div>
            <vs-table :data="detailDo">
              <thead>
                <tr>
                  <th>SKU Name</th>
                  <th>HU</th>
                  <th>Qty</th>
                  <th>Batch Number</th>
                  <th>Expired Date</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="index" v-for="(pl, index) in detailDo">
                  <td>{{ pl.ItemName }}</td>
                  <td>{{ pl.ItemUnit }}</td>
                  <td>{{ pl.Amount }}</td>
                  <td>{{ pl.Batch }}</td>
                  <td>{{ pl.ExpiredDate }}</td>
                </tr>
              </tbody>
            </vs-table>
          </vs-col>

          <!-- <div class="vx-col">
                    <vs-table>
                        <thead>
                            <tr>
                                <th>SKU Name</th>
                                <th>HU</th>
                                <th>Qty</th>
                                <th>Batch Number</th>
                                <th>Expired Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(pl, index) in detailDo">
                                <td>{{ pl.ItemName }}</td>
                                <td>{{ pl.ItemUnit }}</td>
                                <td>{{ pl.Amount }}</td>
                                <td>{{ pl.Batch }}</td>
                                <td>{{ pl.ExpiredDate }}</td>
                            </tr>
                        </tbody>
                    </vs-table>
                </div> -->
          <!-- </vs-row> -->
        </div>
        <div v-else></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: false,
      detailS: false,
      detailDo: [],
      table: this.tableDefaultState(),
      customers: {
        selected: { id: 0, text: "All" },
        options: [{ id: 0, text: "All" }],
      },
    };
  },
  methods: {
    handleFilter() {
      this.getData();
    },
    handleSearchChange(searchTerm) {
      this.$http
        .get("/api/wms/v1/master/customers", {
          params: {
            search: searchTerm,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.customers.options = [
              { id: 0, text: "All" },
              ...resp.data.records.map((c) => ({
                id: c.id,
                text: `${c.code} ${c.name}`,
              })),
            ];
            this.$vs.loading.close();
          } else {
            console.log("DELIVERY PLANS DATA NOT FOUND");
          }
        });
    },
    handleSearchClear() {
      this.customers.options = [];
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.detailS = false;
      this.detail = false;
      this.getData();
      // setTimeout(() => this.detail = false, 800)
    },
    handleDetail(do_id) {
      this.$emit("edit", { do_id });
      // this.detail = true
      // console.log(do_id)
      // this.$vs.loading();
      // this.$http
      //     .get(`/api/wms/v1/simple-outbound-planner/do/${do_id}`)
      //     .then((resp) => {
      //         if (resp.code == 200) {
      //             setTimeout(() => this.detailS = true, 1000)
      //             this.detailDo = resp.data.Packing.PackingLine
      //             this.$vs.loading.close();
      //         } else {
      //             this.$vs.loading.close();
      //         }
      //     });
    },
    handleConfirm(do_id, status) {
      if (status == 4) {
        this.$vs.loading();
        this.$http
          .patch(`/api/wms/v1/simple-outbound-planner/complete`, {
            delivery_order_id: do_id,
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.getData();
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please Confirm POD first",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    handlePrint(do_id) {
      console.log("print", do_id);
      window.open(`/outbound/simple-outbound-processing/pickup/print/${do_id}`);
    },
    handlePrintProformaInvoice(doId) {
      window.doIds = [doId];
      window.open(`/outbound/simple-outbound-planner/surat-jalan/print2`);
    },
    handlePrintInvoice(deliveryOrder) {
      window.delivery_order_code = deliveryOrder.Code;
      window.open(`/outbound/simple-outbound-planner/invoice/print`);
    },
    handlePrintAll() {
      // ...
    },
    print() {
      // ...
    },
    getData() {
      console.log("getData");
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/simple-outbound-planner/do", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            customer_id: this.customers.selected.id,
            status_delivery: 0,
            pickup: true,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            // if (closeDetail) this.detail = false
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleProcess() {
      console.log(this.selected);
      if (this.selected.length) {
        this.$vs.loading();
        this.$http
          .patch("/api/wms/v1/simple-outbound-planner/process", {
            picking_ids: this.selected,
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.loading.close();
              this.getData();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    handleCancel() {
      console.log("cancel");
      console.log(this.selected);
      if (this.selected.length) {
        this.$vs.loading();
        this.$http
          .patch("/api/wms/v1/simple-outbound-planner/cancel", {
            picking_ids: this.selected,
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.loading.close();
              this.getData();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
  },
  mounted() {
    console.log("mounted");
    this.table = this.tableDefaultState();
    console.log(this.table);
    this.getData();
  },
  watch: {},
  computed: {
    formatStatusDelivery: () => {
      return (statusDelivery) =>
        statusDelivery == 1
          ? "Complete"
          : statusDelivery == 2
          ? "Partial Complete"
          : statusDelivery == 4
          ? "Failed Cancel"
          : "Open";
    },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    // formatDate: () => {
    //     return (val) => moment.utc(val).format("YYYY-MM-DD")
    // },
  },
};
</script>

<style scoped>
.core {
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 100px;
  padding: 5px;
}
</style>
