<template>
  <div>
    <div class="vx-row ml-1 mt-3">
      <div class="vx-row mb-6" style="width: 70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Shipment Doc Number</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <multiselect
            v-model="shipmentNumber"
            :options="shimpentNumbers"
            placeholder="Type to search"
            track-by="text"
            label="text"
            :max-height="125"
            multiple
            @select="onSelectShipment"
          ></multiselect>
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Handover Document</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <multiselect
            v-model="handover"
            :options="handovers"
            placeholder="Type to search"
            track-by="text"
            label="text"
            :max-height="125"
            multiple
            @select="onSelectHandover"
          ></multiselect>
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Handover Date</span>
        </div>
        <!-- <div class="vx-col sm:w-3/4 w-full">
                    <flat-pickr style="width: 100%;" :config="configFlatPickr" v-model="rdd"></flat-pickr>
                </div> -->
        <div class="vx-col sm:w-3/4 w-full">
          <date-range-picker
            style="min-height: 40px"
            class="w-full rounded border-none"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            singleDatePicker="range"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="rdd"
            :linkedCalendars="false"
            :ranges="false"
            @select="
              (val) =>
                $store.dispatch('outbound/processing/updateHandoverDate', val)
            "
          >
            <template v-slot:input="picker">
              <div>
                {{ formatDate(picker.startDate) }} -
                {{ formatDate(picker.endDate) }}
              </div>
            </template>
          </date-range-picker>
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Driver</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <multiselect
            v-model="driver"
            :options="drivers"
            placeholder="Type to search"
            :max-height="125"
            multiple
            @select="onSelectDriver"
          ></multiselect>
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center"></div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
        </div>
      </div>
    </div>
    <div class="vx-row mt-3">
      <div class="vx-col w-full mb-base" v-if="reRenderTable">
        <div class="flex mb-2">
          <div class="flex flex-start" style="width: 50%">
            <v-select
              style="width: 100px"
              :options="table.limits"
              :clearable="false"
              @input="handleChangelength"
              v-model="params.length"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
          </div>
          <div class="mr-auto"></div>
          <div class="flex flex-end">
            <div class="flex flex-end">
              <vs-input
                class="mb-4 md:mb-0 mr-4 round"
                v-model="params.search"
                @keyup="handleSearch"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <vs-table stripe style="width: 100%s" data="table">
          <thead>
            <tr>
              <th>
                <vs-checkbox
                  v-model="checkAll"
                  @click="addCheckAll"
                ></vs-checkbox>
              </th>
              <th>Action</th>
              <th>Shipment Data</th>
              <th>Handover Document</th>
              <th>Delivery Note Data</th>
              <th>Confirmed DO</th>
              <th>#</th>
              <th>Sales Order Data</th>
              <th>Delivery Order Data</th>
              <th>Customer Data</th>
              <th>Ship-to Data</th>
              <th>Request Delivery Data (RDD)</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(tr, indextr) in table.data">
              <tr :key="`outerTable-${indextr}`">
                <vs-td>
                  <vs-checkbox
                    :checked="handoverIds.includes(tr.id)"
                    @click="addCheckHandover(tr.id)"
                  ></vs-checkbox>
                </vs-td>
                <vs-td>
                  <div class="space-x-1">
                    <div class="vx-row">
                      <div class="vx-col w-1/3">
                        <vx-tooltip text="View">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            @click="handleEdit(tr.id)"
                            color="warning"
                            icon="icon-eye"
                            :disabled="
                              tr.HandoverDelivery.length !=
                              tr.HandoverDelivery.filter(
                                (hd) => hd.DeliveryOrderID
                              ).length
                            "
                          />
                        </vx-tooltip>
                      </div>
                      <div class="vx-col w-1/3">
                        <vx-tooltip text="Handover">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            @click="confirmDeliver(tr.id)"
                            icon="icon-shopping-cart"
                            color="success"
                            :disabled="
                              tr.HandoverDelivery.length !=
                              tr.HandoverDelivery.filter(
                                (hd) => hd.DeliveryOrderID
                              ).length
                            "
                          />
                        </vx-tooltip>
                      </div>
                      <div class="vx-col w-1/3">
                        <vx-tooltip text="Return to Shipment Plan">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            @click="handoverReturn(tr.id)"
                            color="danger"
                            icon="icon-corner-down-left"
                            :disabled="tr.Status !== 0"
                          />
                        </vx-tooltip>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="vx-row">
                      <div class="vx-col w-1/3">
                        <vx-tooltip text="Print Handover">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            @click="handlePrint(tr.id)"
                            icon="icon-printer"
                            :disabled="
                              tr.HandoverDelivery.length !=
                              tr.HandoverDelivery.filter(
                                (hd) => hd.DeliveryOrderID
                              ).length
                            "
                          />
                        </vx-tooltip>
                      </div>
                      <div class="vx-col w-1/3">
                        <vx-tooltip text="Print Picking List">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            @click="handlePrintPicking(tr.id)"
                            icon="icon-printer"
                            :disabled="
                              tr.HandoverDelivery.length !=
                              tr.HandoverDelivery.filter(
                                (hd) => hd.DeliveryOrderID
                              ).length
                            "
                          />
                        </vx-tooltip>
                      </div>
                      <div class="vx-col w-1/3">
                        <vx-tooltip text="Print Proforma Invoice">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            @click="
                              handlePrintProformaInvoices(
                                tr.HandoverDelivery.map(
                                  (hd) => hd.DeliveryOrder.id
                                )
                              )
                            "
                            icon="icon-printer"
                            :disabled="
                              tr.HandoverDelivery.length !=
                              tr.HandoverDelivery.filter(
                                (hd) => hd.DeliveryOrderID
                              ).length
                            "
                          />
                        </vx-tooltip>
                      </div>
                    </div>
                  </div>
                </vs-td>
                <vs-td
                  >Shipment: {{ tr.TransportPlan.ShipmentNumber }} <br />Driver:
                  {{ tr.TransportPlan.DriverName }} <br />Driver Status:
                  {{
                    tr.TransportPlan.DriverAvailability.IsLocked
                      ? "Locked"
                      : "Available"
                  }}
                  <br />
                  Vehicle: {{ tr.TransportPlan.VehicleNumber }}
                </vs-td>
                <!-- <vs-td>{{ tr.TransportPlanLine.length > 0 ? tr.TransportPlanLine[0].TransportDeliveryPlanLine.DeliveryOrder.HandoverDelivery.Code : '' }}</vs-td> -->
                <vs-td>{{ tr.Code }}</vs-td>
                <!-- <vs-td>{{ tr.TransportPlanLine.length }}</vs-td> -->
                <vs-td>{{ tr.HandoverDelivery.length }}</vs-td>
                <vs-td>{{
                  tr.HandoverDelivery.filter((hd) => hd.DeliveryOrderID).length
                }}</vs-td>
                <vs-td>
                  <vs-button
                    @click="expand(tr.TransportPlan.ShipmentNumber)"
                    icon-pack="feather"
                    :icon="
                      expands[tr.TransportPlan.ShipmentNumber]
                        ? 'icon-chevrons-down'
                        : 'icon-chevron-right'
                    "
                    type="flat"
                    icon-after
                    color="dark"
                  ></vs-button>
                </vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
              </tr>
              <template v-if="expands[tr.TransportPlan.ShipmentNumber]">
                <template v-for="(t, indext) in tr.HandoverDelivery">
                  <tr :key="`${indextr}-${indext}`" v-if="t.DeliveryOrderID">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div>
                        <vx-tooltip text="Print Proforma Invoice">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            @click="
                              handlePrintProformaInvoice(t.DeliveryOrder.id)
                            "
                            icon="icon-printer"
                            :disabled="
                              tr.HandoverDelivery.length !=
                              tr.HandoverDelivery.filter(
                                (hd) => hd.DeliveryOrderID
                              ).length
                            "
                          />
                        </vx-tooltip>
                      </div>
                    </td>
                    <vs-td>{{ t.DeliveryOrder.SalesOrder.Code }}</vs-td>
                    <vs-td>{{ t.DeliveryOrder.Code }}</vs-td>
                    <vs-td>{{ t.DeliveryOrder.SalesOrder.CustomerName }}</vs-td>
                    <vs-td>{{
                      t.DeliveryOrder.SalesOrder.CustomerAddress
                    }}</vs-td>
                    <vs-td>{{ formatDate(t.DeliveryOrder.Date) }}</vs-td>
                    <!-- <vs-td>
                                            {{ 
                                                t.Status == 2 ? "Handed-over by Warehouse"
                                                : t.Status == 3 ? "Handed-over by Driver"
                                                : "Pending" 
                                            }}
                                        </vs-td> -->
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </vs-table>
        <div class="mb-6 space-x-1 flex">
          <div class="">
            <vs-button color="primary" @click="handlePrintAll('picking')"
              >Print Selected Picking List</vs-button
            >
          </div>
          <div class="">
            <vs-button color="primary" @click="handlePrintAll('handover')"
              >Print Selected Handover</vs-button
            >
          </div>
          <div class="">
            <vs-button color="primary" @click="handleClose()">Close</vs-button>
          </div>
        </div>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import moment from "moment";
import vSelect from "vue-select";
export default {
  components: {
    flatPickr,
    vSelect,
    DateRangePicker,
  },
  data() {
    return {
      reRenderTable: true,
      expands: {},
      table: this.tableDefaultState(),
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "",
        sort: "",
      },
      activeTab: "Handover",
      // rdd: {
      //     startDate: Date.now(),
      //     endDate: Date.now(),
      // },
      rdd: this.$store.state.outbound.processing.handoverDate,
      configFlatPickr: {
        altFormat: "F j, Y",
        dateFormat: "j F Y",
        enableTime: false,
        plugins: [new ConfirmDatePlugin()],
      },
      shimpentNumbers: [{ text: "All", value: 0 }],
      // shipmentNumber: [{text: "All", value: 0}],
      shipmentNumber: this.$store.state.outbound.processing.shipmentNumber,
      handovers: [{ text: "All", value: 0, shipmentID: 0 }],
      // handover: [{text: "All", value: 0, shipmentID: 0}],
      handover: this.$store.state.outbound.processing.handover,
      drivers: ["All"],
      // driver: ["All"],
      driver: this.$store.state.outbound.processing.driver,
      handoverIds: [],
      checkAll: false,
    };
  },
  computed: {
    formatDate: () => {
      return (val) => (val ? moment.utc(val).format("YYYY-MM-DD") : "");
    },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    shipmentNumber(val) {
      if (!val.length) {
        this.shipmentNumber = [{ text: "All", value: 0 }];
        this.$store.dispatch(
          "outbound/processing/updateShipmentNumber",
          this.shipmentNumber
        );
      }
      const snID = this.shipmentNumber.map((sn) => sn.value);
      this.handover = this.handovers.filter((h) => snID.includes(h.shipmentID));
      this.$store.dispatch("outbound/processing/updateHandover", this.handover);
    },
    handover(val) {
      if (!val.length) {
        this.handover = [{ text: "All", value: 0, shipmentID: 0 }];
        this.$store.dispatch(
          "outbound/processing/updateHandover",
          this.handover
        );
      }
    },
    driver(val) {
      if (!val.length) {
        this.driver = ["All"];
        this.$store.dispatch("outbound/processing/updateDriver", this.driver);
      }
    },
  },
  mounted() {
    this.$vs.loading();
    this.$http
      .get(`/api/wms/v1/simple-outbound-planner/shipment-number`, {
        params: {
          status: [0, 1],
        },
      })
      .then((resp) => {
        if (resp.code == 200) {
          this.shimpentNumbers = [
            { text: "All", value: 0 },
            ...resp.data.map((s) => ({ text: s.ShipmentNumber, value: s.ID })),
          ];
          this.drivers = ["All", ...resp.data.map((s) => s.DriverName)];
          //     this.$vs.loading.close();
          // } else {
          //     this.$vs.loading.close();
        }
      })
      .then(() => {
        return (
          this.$http
            // .get("/api/wms/v1/simple-outbound-planner/handover-delivery")
            .get("/api/wms/v1/simple-outbound-planner/handover-list", {
              params: {
                status: [0, 1],
              },
            })
        );
      })
      .then((resp) => {
        if (resp.code == 200) {
          this.handovers = [
            { text: "All", value: 0, shipmentID: 0 },
            ...resp.data.map((s) => ({
              text: s.Code,
              value: s.ID,
              shipmentID: s.ShipmentID,
            })),
          ];
          //     this.$vs.loading.close();
          // } else {
          //     this.$vs.loading.close();
        }
      })
      // .then(() => {
      //     return this.$http.get("/api/wms/v1/simple-outbound-planner/driver")
      // })
      // .then(resp => {
      //     if (resp.code == 200) {
      //         this.drivers = [{text: "All", value: 0}, ...resp.data.map(d => ({text: d.name, value: d.id}))]
      //         this.$vs.loading.close();
      //     } else {
      //         this.$vs.loading.close();
      //     }
      // })
      .then(() => {
        this.getData();
      });
  },
  methods: {
    onSelectShipment(selected) {
      if (!selected.value) {
        this.shipmentNumber = [{ text: "All", value: 0 }];
        this.$store.dispatch(
          "outbound/processing/updateShipmentNumber",
          this.shipmentNumber
        );
      } else {
        const idxAll = this.shipmentNumber.map((sn) => sn.value).indexOf(0);
        if (idxAll > -1) {
          this.shipmentNumber.splice(idxAll, 1);
          this.$store.dispatch(
            "outbound/processing/updateShipmentNumber",
            this.shipmentNumber
          );
        }
      }
    },
    onSelectHandover(selected) {
      if (!selected.value) {
        this.handover = [{ text: "All", value: 0, shipmentID: 0 }];
        this.$store.dispatch(
          "outbound/processing/updateHandover",
          this.handover
        );
      } else {
        const idxAll = this.handover.map((sn) => sn.value).indexOf(0);
        if (idxAll > -1) {
          this.handover.splice(idxAll, 1);
          this.$store.dispatch(
            "outbound/processing/updateHandover",
            this.handover
          );
        }
      }
    },
    onSelectDriver(selected) {
      if (selected == "All") {
        this.driver = ["All"];
        this.$store.dispatch("outbound/processing/updateDriver", this.driver);
      } else {
        const idxAll = this.driver.indexOf("All");
        if (idxAll > -1) {
          this.driver.splice(idxAll, 1);
          this.$store.dispatch("outbound/processing/updateDriver", this.driver);
        }
      }
    },
    addCheckHandover(hoId) {
      if (this.handoverIds.includes(hoId))
        this.handoverIds.splice(this.handoverIds.indexOf(hoId), 1);
      else this.handoverIds.push(hoId);
    },
    addCheckAll() {
      this.handoverIds = [];
      if (!this.checkAll) {
        this.handoverIds = this.table.data.map((h) => h.id);
      }
    },
    expand(code) {
      this.expands[code] = !this.expands[code];
      this.reRenderTable = false;
      this.$nextTick(() => (this.reRenderTable = true));
    },
    handleFilter() {
      this.getData();
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      if (this.table.page != page) {
        this.table.page = page;
        this.getData();
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      // ...
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/simple-outbound-planner/handover-data", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            // ...
            // shipment_id: this.shipmentNumber.value,
            shipment_ids: this.shipmentNumber.map((sn) => sn.value),
            // handover_id: this.handover.value,
            handover_ids: this.handover.map((h) => h.value),
            // handover_date: moment(this.rdd).format("YYYY-MM-DD"),
            from_handover_date: moment(this.rdd.startDate).format("YYYY-MM-DD"),
            to_handover_date: moment(this.rdd.endDate).format("YYYY-MM-DD"),
            // driver_id: this.driver.value,
            drivers: this.driver,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            console.log(resp.data);
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handlePrintProformaInvoice(doId) {
      window.doIds = [doId];
      // window.open(`/outbound/simple-outbound-planner/surat-jalan/print`);
      window.open(`/outbound/simple-outbound-planner/surat-jalan/print3`);
    },
    handlePrintProformaInvoices(doIds) {
      window.doIds = doIds;
      // window.open(`/outbound/simple-outbound-planner/surat-jalan/print`);
      window.open(`/outbound/simple-outbound-planner/surat-jalan/print3`);
    },
    handlePrintAll(doc) {
      if (this.handoverIds.length > 0) {
        window.handoverIds = this.handoverIds;
        window.handoverStatus = 0;
        if (doc == "picking")
          window.open(`/outbound/simple-outbound-processing/picking/print`);
        else if (doc == "handover")
          window.open(`/outbound/simple-outbound-processing/handover/print`);
      } else {
        this.$vs.notify({
          color: "warning",
          title: "Print",
          text: "Select document first",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    handlePrint(handoverId) {
      window.handoverIds = [handoverId];
      window.handoverStatus = 0;
      window.open(`/outbound/simple-outbound-processing/handover/print`);
    },
    handlePrintPicking(handoverId) {
      window.handoverIds = [handoverId];
      window.open(`/outbound/simple-outbound-processing/picking/print`);
    },
    handleEdit(handoverId) {
      this.$emit("edit", { handover_id: handoverId });
      // this.$router.push(
      //     {
      //         name: "simple-outbound-planner-handover-view"
      //     }
      // );
    },
    confirmDeliver(handoverId) {
      console.log("handoverId", handoverId);
      this.$vs.loading();
      this.$http
        // .patch("/api/wms/v1/simple-outbound-planner/deliver", {handover_id: handoverId})
        .post("/api/wms/v1/simple-outbound-planner/deliver", {
          handover_id: handoverId,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.loading.close();
            this.getData();
            this.$vs.notify({
              title: "Success",
              text: resp.message,
              color: "success",
              position: "top-right",
            });
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Failed",
              text: resp.message,
              color: "danger",
              position: "top-right",
            });
          }
        });
    },

    handoverReturn(id) {
      var getData = this.getData;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Return to Shipment Plan Confirmation`,
        text: "Please confirm to process this data",
        accept: function () {
          this.$vs.loading();
          this.$http
            .post("/api/wms/v1/simple-outbound-processing/handover-return", {
              handover_id: id,
            })
            .then((resp) => {
              this.$vs.loading.close();
              getData();
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully proceessed",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },
    // accepthandoverReturn() {
    //   this.$vs.loading();
    //   this.$http
    //   .post("/api/wms/v1/simple-outbound-processing/handover-return", {
    //       handover_id: id,
    //     })
    //     .then((resp) => {
    //       this.$vs.loading.close();
    //       if (resp.code == 200) {
    //         this.$vs.notify({
    //           color: "success",
    //           title: "Success",
    //           text: "The data was successfully deleted",
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       } else {
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       }
    //       this.getData();
    //     })
    //     .catch((error) => {
    //       this.$vs.loading.close();
    //       console.log(error);
    //     });
    // },
    // handoverReturn(handoverId) {
    //   console.log("handoverId", handoverId);
    //   this.$vs.loading();
    //   this.$http
    //     .post("/api/wms/v1/simple-outbound-processing/handover-return", {
    //       handover_id: handoverId,
    //     })
    //     .then((resp) => {
    //       if (resp.code == 200) {
    //         this.$vs.loading.close();
    //         this.getData();
    //         this.$vs.notify({
    //           title: "Success",
    //           text: resp.message,
    //           color: "success",
    //           position: "top-right",
    //         });
    //       } else {
    //         this.$vs.loading.close();
    //         this.$vs.notify({
    //           title: "Failed",
    //           text: resp.message,
    //           color: "danger",
    //           position: "top-right",
    //         });
    //       }
    //     });
    // },
  },
};
</script>
